import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuState } from "../context/MenuContext";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";

const Cart = (props) => {
  const location = useLocation();
  const { setOrderDetail, cartData, setCartData } = MenuState();

  useEffect(() => {
    // console.log("Cart data updated:", cartData);
  }, [cartData]); // This effect depends on cartData and runs whenever cartData changes

  const [restaurantName, setRestaurantName] = useState("");

  useEffect(() => {
    // Create a URLSearchParams object to parse the query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Extract the Restaurant parameter
    const restaurantName = queryParams.get("tenantName");

    // Set the Restaurant state
    setRestaurantName(restaurantName);
  }, []);

  // const handleQuantityChange = (index, delta) => {
  //   setCartData((prevCartData) => {
  //     // Clone the previous cart items array to avoid direct mutations
  //     const newCartItems = prevCartData.cartItems.map((item) => ({ ...item }));
  //     let itemToUpdate = newCartItems[index];

  //     // Safely convert delta to an integer
  //     const integerDelta = Math.round(delta);

  //     // Calculate the new quantity, ensuring it doesn't drop below 1
  //     const newQuantity = Math.max(itemToUpdate.Quantity + integerDelta, 1);
  //     itemToUpdate.Quantity = newQuantity;

  //     // Recalculate the total price for the updated item
  //     itemToUpdate.TotalPrice = itemToUpdate.ItemPrice * itemToUpdate.Quantity;

  //     // Update the total price for the cart
  //     const newTotalPrice = newCartItems.reduce(
  //       (total, currentItem) => total + currentItem.TotalPrice,
  //       0
  //     );

  //     return {
  //       ...prevCartData,
  //       cartItems: newCartItems,
  //       total_Price: newTotalPrice,
  //     };
  //   });
  // };

  const handleQuantityChange = (index, delta) => {
    setCartData((prevCartData) => {
      // Clone the previous cart items array to avoid direct mutations
      const newCartItems = prevCartData.cartItems.map((item) => ({ ...item }));
      let itemToUpdate = newCartItems[index];

      // Safely convert delta to an integer
      const integerDelta = Math.round(delta);

      // Calculate the new quantity, ensuring it doesn't drop below 1
      const newQuantity = Math.max(itemToUpdate.Quantity + integerDelta, 1);
      itemToUpdate.Quantity = newQuantity;

      // Calculate the total price for the item itself
      const itemTotalPrice = itemToUpdate.ItemPrice * itemToUpdate.Quantity;

      // Calculate the total price of the modifiers (without changing them based on quantity)
      const modifiersTotalPrice =
        itemToUpdate.Modifiers?.reduce(
          (total, modifier) => total + (modifier.Price || 0),
          0
        ) || 0;

      // Update the total price for the updated item including static modifier prices
      itemToUpdate.TotalPrice = itemTotalPrice + modifiersTotalPrice;

      // Update the total price for the cart
      const newTotalPrice = newCartItems.reduce(
        (total, currentItem) => total + currentItem.TotalPrice,
        0
      );

      return {
        ...prevCartData,
        cartItems: newCartItems,
        total_Price: newTotalPrice,
      };
    });
  };

  const handleDeleteItem = (index, itemId) => {
    const newCartData = { ...cartData };
    // Remove the item from the cartItems array
    newCartData.cartItems.splice(index, 1);

    // Update subtotal after removing the item, including modifiers' prices
    newCartData.total_Price = newCartData.cartItems.reduce((total, item) => {
      // Calculate item total price including its modifiers
      const itemTotal = item.ItemPrice * item.Quantity;
      const modifiersTotal = item.Modifiers.reduce(
        (modTotal, modifier) => modTotal + modifier.Price,
        0
      );
      // Return the accumulated total plus the item total including modifiers
      return total + itemTotal + modifiersTotal;
    }, 0);

    setCartData(newCartData);
  };

  const navigate = useNavigate();

  const goToOrderPage = () => {
    // Assuming cartData is an array, check if it has any items
    if (cartData.cartItems.length > 0) {
      setOrderDetail(cartData);
      const tenantId = cartData?.cartItems?.find((item) => item)?.tenantId;
      // console.log(tenantId);
      navigate("/order-page", {
        state: {
          tenantId: tenantId,
        },
      });
    } else {
      toast(
        `Your cart is empty. Please add some items before proceeding to checkout.`,
        {
          position: "top-center",
          type: "error",
          autoClose: 1400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  return (
    <Offcanvas placement="end" show={props.show} onHide={props.onHide}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Your Cart</Offcanvas.Title>
      </Offcanvas.Header>
      {cartData?.cartItems?.length === 0 || cartData?.cartItems?.length < 1 ? (
        <Offcanvas.Body>
          <div
            style={{ minHeight: "60vh" }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <p style={{ color: "#982121" }}>
              Your cart is empty. Add items to continue
            </p>
          </div>
        </Offcanvas.Body>
      ) : (
        <Offcanvas.Body>
          {cartData?.cartItems?.map((item, index) => {
            return (
              <>
                <div className="shadow p-3" key={index}>
                  <div className="d-flex flex-column">
                    <div className="d-flex">
                      {item.imageFullPath === null ? (
                        <img
                          className="custom-card-img"
                          src="https://wiki.tripwireinteractive.com/TWIimages/4/47/Placeholder.png?20121020050736"
                          alt="kpg"
                          style={{
                            width: "60px",
                            height: "60px",
                            borderRadius: "5px",
                            boxShadow: "silver 0.3px 0.3px 5px",
                          }}
                        />
                      ) : (
                        <img
                          style={{
                            width: "60px",
                            height: "60px",
                            borderRadius: "5px",
                            boxShadow: "silver 0.3px 0.3px 5px",
                          }}
                          // src={`http://portal.diner-tab.com/GoStoraImages/Company/1/Products/${item?.Image}`}
                          src={`https://${restaurantName}.diner-tab.com/${item?.imageFullPath}`}
                          alt="img"
                        />
                      )}

                      <div className="d-flex flex-row">
                        <div className="ps-3">
                          <span>{item.Name}</span>

                          <div style={{ cursor: "pointer" }}>
                            <span
                              onClick={() => handleQuantityChange(index, -1)}
                            >
                              -
                            </span>
                            <span className="px-3">{item.Quantity}</span>
                            <span
                              onClick={() => handleQuantityChange(index, 1)}
                            >
                              +
                            </span>

                            <MdDelete
                              className="ms-2"
                              size={20}
                              color="#c00"
                              onClick={() => handleDeleteItem(index, item.Id)}
                            />
                          </div>
                        </div>

                        <div className="ps-3">
                          <span>PKR</span>
                          <span className="ms-1">
                            {item.ItemPrice.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {item.Modifiers && item.Modifiers.length > 0 && (
                    <div className="pt-3">
                      <span>Modifiers</span>
                      {item.Modifiers.map((modifier, index) => (
                        <div
                          className="d-flex justify-content-between align-items-center pt-3"
                          key={index}
                        >
                          <div>
                            <span>{modifier.Title}</span>
                          </div>
                          <div>
                            <span>PKR {modifier.Price}</span>
                          </div>
                        </div>
                      ))}
                      <hr />
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </Offcanvas.Body>
      )}

      <div className="container">
        <hr />

        <div className="d-flex justify-content-between">
          <span>Total</span>
          <span>PKR {cartData.total_Price.toFixed(2)}</span>
        </div>
        <div className="d-flex justify-content-center">
          {location.pathname === "/order-page" ? null : (
            <button onClick={goToOrderPage} className="global-btn mb-2">
              Contine Your Cart
            </button>
          )}
        </div>
      </div>
    </Offcanvas>
  );
};

export default Cart;
