import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Data from "../Data.json";
import { MenuState } from "../context/MenuContext";
import Cart from "../components/Cart";
import CustomHeader from "../components/CustomHeader";
import { MdContactSupport } from "react-icons/md";
import { toast } from "react-toastify";

const MenuDetail = () => {
  const navigate = useNavigate();
  const { cartData, setCartData } = MenuState();

  const [show, setShow] = useState(false);

  const [restaurantName, setRestaurantName] = useState("");

  useEffect(() => {
    // Create a URLSearchParams object to parse the query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Extract the Restaurant parameter
    const restaurantName = queryParams.get("tenantName");

    // Set the tableName state
    setRestaurantName(restaurantName);
  }, []);

  const { state } = useLocation();
  const detail = state && state.detail;

  const modiferData = state && state.posData;
  const modifiers = modiferData?.modifiers;

  const handleClose = () => setShow(false);

  // console.log(state, "state");
  // console.log(modifiers, "modifiers");

  const addToCart = (newData) => {
    setCartData((prevCartData) => {
      const existingIndex = prevCartData.cartItems.findIndex(
        (item) => item.Id === newData.id
      );
      let newCartItems;

      if (existingIndex !== -1) {
        // Item exists, update quantity and total price
        newCartItems = [...prevCartData.cartItems];
        const existingItem = newCartItems[existingIndex];
        const newQuantity = existingItem.Quantity + 1;
        const newTotalPrice = existingItem.ItemPrice * newQuantity;
        newCartItems[existingIndex] = {
          ...existingItem,
          Quantity: newQuantity,
          TotalPrice: newTotalPrice,
        };

        toast(`Cart Updated: Item Quantity Increased.`, {
          position: "top-center",
          type: "success",
          autoClose: 1400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // Item doesn't exist, add new item
        const newItem = {
          ...newData,
          Id: newData.id,
          Name: newData.itemName,
          ItemPrice: newData.price,
          Quantity: 1,
          TotalPrice: newData.price,
          Image: newData.image,
          Modifiers: [],
        };
        newCartItems = [...prevCartData.cartItems, newItem];

        toast(`Item added to cart.`, {
          position: "top-center",
          type: "success",
          autoClose: 1400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      // Recalculate the total price
      const newTotalPrice = newCartItems.reduce(
        (total, item) => total + item.TotalPrice,
        0
      );

      return {
        cartItems: newCartItems,
        total_Price: newTotalPrice,
      };
    });
  };

  const addModifier = (modifierData, itemId) => {
    if (cartData.cartItems.length === 0) {
      toast(`Please add an item to the cart before adding modifiers.`, {
        position: "top-center",
        type: "error",
        autoClose: 1400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const updatedCartItems = cartData.cartItems.map((item) => {
      if (item.Id === itemId) {
        // Found the item, now add the modifier
        const newModifier = {
          Id: modifierData.id,
          Title: modifierData.title,
          Price: modifierData.price,
        };
        const updatedModifiers = item.Modifiers
          ? [...item.Modifiers, newModifier]
          : [newModifier];

        toast(`Modifier added to cart successfully.`, {
          position: "top-center",
          type: "success",
          autoClose: 1400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // Calculate new total price for the item including all modifiers
        const modifierTotalPrice = updatedModifiers.reduce(
          (acc, mod) => acc + mod.Price,
          0
        );
        const updatedTotalPrice =
          item.ItemPrice * item.Quantity + modifierTotalPrice;

        return {
          ...item,
          Modifiers: updatedModifiers,
          TotalPrice: updatedTotalPrice,
        };
      }

      return item;
    });

    // Update the cartData with the updated cart items and recalculated total cart price
    setCartData((prevData) => {
      const newTotalPrice = updatedCartItems.reduce(
        (total, item) => total + item.TotalPrice,
        0
      );
      return {
        ...prevData,
        cartItems: updatedCartItems,
        total_Price: newTotalPrice,
      };
    });
  };

  return (
    <>
      <Cart show={show} onHide={handleClose} />

      <div className="menu-detail">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4">
              <CustomHeader
                onBackClick={() => navigate(-1)}
                onCartIconClick={() => setShow(true)}
                cartQty={cartData.cartItems.length}
              />

              {detail.imageFullPath === null ? (
                <img
                  className="custom-card-img"
                  src="https://wiki.tripwireinteractive.com/TWIimages/4/47/Placeholder.png?20121020050736"
                  alt="kpg"
                  style={{ width: "100%", height: "320px" }}
                />
              ) : (
                <img
                  className="img-fluid"
                  // src={`https://portal.diner-tab/GoStoraImages/Company/1/Products/${x.imageFullPath}`}
                  src={`https://${restaurantName}.diner-tab.com/${detail?.imageFullPath}`}
                  alt="img"
                />
              )}

              <div className="pt-2">
                <h4>{detail?.itemName}</h4>
                <p className="m-0">{detail?.description}</p>
              </div>

              <div className="pt-4">
                <div className="d-flex justify-content-between align-items-center">
                  <span>PKR {detail?.price}</span>
                  <button
                    onClick={() => addToCart(detail)}
                    className="modifer-btn"
                  >
                    +
                  </button>
                </div>
                <hr />
              </div>

              <h3>Modifers</h3>
              {/* {modifiers?.map((x, index) => {
                return (
                  <div key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{x.title}</span>
                      <span>PKR {x.price}</span>
                      <button
                        className="modifer-btn"
                        onClick={() => addModifier(x, detail?.id)}
                      >
                        +
                      </button>
                    </div>
                    <hr />
                  </div>
                );
              })} */}

              {modifiers && modifiers.length > 0 ? (
                modifiers.map((x, index) => (
                  <div key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>{x.title}</span>
                      <span>PKR {x.price}</span>
                      <button
                        className="modifier-btn"
                        onClick={() => addModifier(x, detail?.id)}
                      >
                        +
                      </button>
                    </div>
                    <hr />
                  </div>
                ))
              ) : (
                <div>
                  <i>No modifiers are found here</i>
                </div>
              )}
            </div>
            <div className="col-xl-8"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuDetail;
