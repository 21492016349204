import React from "react";
import SectionSlider from "../components/SectionSlider";
import { useLocation } from "react-router-dom";
import { MenuState } from "../context/MenuContext";

const MenuPage = () => {
  const { resId, setResId, tableName, setTableName, resName, setResName } =
    MenuState();
  const location = useLocation();

  // Parse the search string to get query parameters
  const searchParams = new URLSearchParams(location.search);
  // const restaurantId = searchParams.get("restaurantId");
  // const restaurantId = searchParams.get("tenantId");
  const restaurantId = searchParams.get("tenantId");
  const restaurantName = searchParams.get("tenantName");
  const TableName = searchParams.get("TableName");

  // navigate("/menustart?tenantName=portal?tenantId=1&TableName=T4");
  //app.diners-tab.com/menustart?tenantName=portal?tenantId=1&TableName=T4

  setTableName(TableName);
  setResId(restaurantId);
  setResName(restaurantName);

  // console.log(restaurantId, "tenantId");
  // console.log(TableName, "TableName");
  // console.log(restaurantName, "tenantName");

  return (
    <div className="menu-page">
      <SectionSlider restaurantId={restaurantId} />
    </div>
  );
};

export default MenuPage;
