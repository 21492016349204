import React, { useEffect } from "react";
import { CiUser } from "react-icons/ci";
import { MdPayment } from "react-icons/md";
import { CiPhone } from "react-icons/ci";
import { SiVirustotal } from "react-icons/si";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuState } from "../context/MenuContext";
import ThankYouImage from "../Assets/Images/thank-you.png";

const OrderDetail = () => {
  // const { orderResponse } = MenuState();
  // console.log(orderResponse);

  const location = useLocation();
  const { orderResponse } = location.state || {};

  const { resId, tableName, resName } = MenuState();

  // console.log(resId, tableName, resName, "hhh");

  console.log(orderResponse, "kkkkk");

  const navigate = useNavigate();

  // const goToHome = () => {
  //   navigate("/");
  // };

  const goToHome = () => {
    const newUrl = `/menustart?tenantName=${resName}&tenantId=${resId}&TableName=${tableName}`;
    console.log(newUrl, "new url");
    // navigate("/menustart?tenantName=portal&tenantId=1&TableName=T4");
    navigate(newUrl);
  };

  return (
    <div className="order-detail">
      <div className="container d-md-flex flex-column justify-content-center vh-100">
        <div className="row px-lg-5">
          <div className="thank col-md-6">
            <img
              className="img-fluid "
              src={ThankYouImage}
              alt="ThankYouImage"
            />
          </div>

          <div className="col-md-6 px-lg-5">
            <div className="mobile-thank">Thank You!</div>
            <div className="inner shadow p-3">
              <div className="heading-box text-center">
                <h4>Order Details</h4>
              </div>

              <div className="d-flex align-items-center py-2">
                <div className="icon-box">
                  <CiUser size={20} color="white" />
                </div>
                <span className="ms-3">
                  <strong> Customer Name :</strong>{" "}
                  {orderResponse?.order?.customer?.customerName}
                </span>
              </div>

              <div className="d-flex align-items-center py-2">
                <div className="icon-box">
                  <CiPhone size={20} color="white" />
                </div>
                <span className="ms-3">
                  <strong>Phone Number :</strong>{" "}
                  {orderResponse?.order?.customer?.phone}
                </span>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="icon-box">
                  <MdPayment size={20} color="white" />
                </div>
                <span className="ms-3">
                  <strong>Payment Method :</strong>{" "}
                  {orderResponse?.order?.paymentMethod}
                </span>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="icon-box">
                  <SiVirustotal size={20} color="white" />
                </div>
                <span className="ms-3">
                  <strong>Sub Total :</strong> PKR{" "}
                  {orderResponse?.order?.subTotal}
                </span>
              </div>

              <div className="list-items">
                <h4 className="heading-box text-center">Order Items</h4>
                {orderResponse?.order.runningOrderDetails?.map((x, index) => {
                  return (
                    <ul key={index}>
                      <li>
                        <strong>Item Name :</strong> {x?.item?.itemName}
                      </li>
                      {x.runningOrderItemModifiers.length > 0 ? (
                        <h5>Modifiers</h5>
                      ) : null}
                      {x.runningOrderItemModifiers?.map((m, index) => {
                        return (
                          <ul>
                            <li>Modifier Name : {m.modifier?.title}</li>
                          </ul>
                        );
                      })}
                    </ul>
                  );
                })}
              </div>
              <button onClick={goToHome} className="mt-4 global-btn">
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
