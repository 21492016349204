import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { MenuState } from "../context/MenuContext";
import CustomHeader from "../components/CustomHeader";
import Cart from "../components/Cart";
import { useLocation, useNavigate } from "react-router-dom";
import apiFunctions from "../global/GlobalFunction";
import { BASE_URL, API_URL, BASE_2 } from "../global/Constant";
import { toast } from "react-toastify";

const OrderPage = () => {
  const { orderDetail, setOrderResponse, cartData, setCartData, tableName } =
    MenuState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const location = useLocation();
  const { tenantId } = location.state || "1";
  // console.log(tenantId, "from navigate sy pass");

  const navigate = useNavigate();

  // console.log(orderDetail, "hadi");

  const [customerName, setCustomerName] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  // const [tenantId, setTenantId] = useState("");
  const [restaurantName, setRestaurantName] = useState("");

  useEffect(() => {
    // Create a URLSearchParams object to parse the query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Extract the Restaurant parameter
    const restaurantName = queryParams.get("tenantName");
    // const restaurantId = queryParams.get("tenantId");

    // Set the Restaurant state
    setRestaurantName(restaurantName);
    // setTenantId(restaurantId);
  }, []);

  // useEffect(() => {
  //   getPosData();
  // }, []);

  // async function getPosData() {
  //   try {
  //     let getPosData = await apiFunctions.GET_REQUEST(
  //       // "https://thxdd077-7104.inc1.devtunnels.ms/api/GetOrderFromOther/GetPOSData?resturantId=1"
  //       // BASE_URL + API_URL.GET_POS_DATA + `?resturantId=${resId}`
  //       BASE_URL + API_URL.GET_POS_DATA
  //     );
  //     let res = getPosData?.data.resturantDetail.id;
  //     // setTenantId(res);
  //     console.log(tenantId, "tenant id");
  //     // console.log(res, "getPosData");
  //   } catch (err) {
  //     console.log("An error occurred while fetching menus", err.message);
  //   }
  // }

  let customFont = {
    fontSize: "20px",
  };

  // const handleQuantityChange = (index, delta) => {
  //   setCartData((prevCartData) => {
  //     const newCartItems = [...prevCartData.cartItems];
  //     let item = newCartItems[index];

  //     // Adjust quantity
  //     const newQuantity = item.Quantity + delta;
  //     item.Quantity = Math.max(newQuantity, 1); // Ensure quantity isn't negative

  //     // Recalculate item and total prices
  //     const modifiersPrice = item.Modifiers.reduce(
  //       (acc, modifier) => acc + modifier.Price,
  //       0
  //     );
  //     item.TotalPrice = item.ItemPrice * item.Quantity + modifiersPrice;

  //     const newTotalPrice = newCartItems.reduce(
  //       (total, item) => total + item.TotalPrice,
  //       0
  //     );

  //     return {
  //       cartItems: newCartItems,
  //       total_Price: newTotalPrice,
  //     };
  //   });
  // };

  function getFormattedDateTime() {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const confirmOrder = async () => {
    const data = {
      TenantId: tenantId,
      CustomerName: customerName,
      TableName: tableName,
      PhoneNumber: phoneNumber,
      Items: orderDetail.cartItems,
      PaymentMethod: paymentMethod,
      Instructions: specialInstructions,
      Subtotal: orderDetail.total_Price,
      Address: "abc",
      OrderType: 1,
      // PickupTime: "2024-02-05T10:10:36",
      PickupTime: getFormattedDateTime(),
      Note: "note",
      WaiterOrDriver: "Waiter",
    };

    console.log(data, "data");

    if (!customerName || !paymentMethod || !phoneNumber) {
      toast(`Please enter all required fields.`, {
        position: "top-center",
        type: "error",
        autoClose: 1400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    try {
      let orderResponse;
      orderResponse = await apiFunctions.POST_REQUEST(
        BASE_URL + API_URL.SAVING_RUNNING_ORDER,
        data
      );

      console.log(orderResponse, "kkk");

      setOrderResponse(orderResponse?.data);

      toast(`Your order has been confirmed.`, {
        position: "top-center",
        type: "success",
        autoClose: 1400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // navigate("/order-detail");

      navigate("/order-detail", {
        state: { orderResponse: orderResponse?.data },
      });

      setCartData({
        cartItems: [],
        total_Price: 0,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  console.log(orderDetail.cartItems);
  return (
    <>
      <Cart show={show} onHide={handleClose} />
      <div className="container pt-2">
        <CustomHeader
          onBackClick={() => navigate(-1)}
          onCartIconClick={() => setShow(true)}
          cartQty={cartData.cartItems.length}
          hideCart={"hidecart"}
        />
      </div>
      <div
        style={{ minHeight: "100vh" }}
        className="order-main container d-xl-flex flex-column justify-content-center"
      >
        <div className="row order-screen">
          {orderDetail?.cartItems?.map((x, index) => {
            return (
              <>
                <div key={index} className="web-view col-lg-8 px-lg-5 py-lg-3">
                  <div className="shadow p-4">
                    <div className="d-md-flex align-items-center">
                      {x.imageFullPath === null ? (
                        <img
                          className="custom-card-img"
                          src="https://wiki.tripwireinteractive.com/TWIimages/4/47/Placeholder.png?20121020050736"
                          alt="kpg"
                        />
                      ) : (
                        <img
                          className="order-image"
                          // src={`http://portal.diner-tab.com/GoStoraImages/Company/1/Products/${x?.Image}`}
                          src={`https://${restaurantName}.diner-tab.com/${x?.imageFullPath}`}
                          alt="img"
                        />
                      )}

                      <div className="item-data d-md-flex flex-column ms-md-5">
                        <span style={customFont}>{x.Name}</span>
                        <span style={customFont}>Price: PKR {x.ItemPrice}</span>
                        <span style={customFont}>Quantity: {x.Quantity}</span>
                      </div>
                    </div>
                    {x.Modifiers && x.Modifiers.length > 0 && (
                      <div>
                        <h5 className="mt-2">Modifiers</h5>
                        <hr />
                        {x.Modifiers.map((modifier, index) => (
                          <div
                            className="d-flex justify-content-between align-items-center pt-1"
                            key={index}
                          >
                            <div>
                              <span>{modifier.Title}</span>
                            </div>
                            <div>
                              <span>PKR {modifier.Price}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-8 mobile-view py-2">
                  <div className="d-flex align-items-center shadow p-3">
                    <div>
                      {x.imageFullPath === null ? (
                        <img
                          className="custom-card-img"
                          src="https://wiki.tripwireinteractive.com/TWIimages/4/47/Placeholder.png?20121020050736"
                          alt="kpg"
                        />
                      ) : (
                        <img
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "10px",
                          }}
                          // src={`http://portal.diner-tab.com/GoStoraImages/Company/1/Products/${x?.Image}`}
                          src={`https://${restaurantName}.diner-tab.com/${x?.imageFullPath}`}
                        />
                      )}
                    </div>
                    <div className="ms-5">
                      <div className="d-flex flex-column">
                        <span>{x.Name}</span>
                        <span>Size: small</span>
                        <span>Price:{x.ItemPrice}</span>
                        <span>Quantity: {x.Quantity}</span>
                      </div>

                      {x.Modifiers && x.Modifiers.length > 0 && (
                        <div className="py-3">
                          <hr className="mt-0" />
                          <h6>Modifiers:</h6>
                          {x.Modifiers.map((modifier, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex align-items-center justify-content-between"
                              >
                                <span>{modifier.Title}</span>
                                <span>{modifier.Price}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          <div className="col-lg-4 order-form py-lg-3">
            <div className="shadow p-3">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label className="required">Customer Name</Form.Label>
                  <Form.Control
                    value={customerName}
                    // onChange={(e) => setCustomerName(e.target.value)}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Regular expression to check for numeric values
                      const hasNumbers = /\d/.test(value);
                      if (!hasNumbers) {
                        setCustomerName(value); // Update only if no numbers are found
                      }
                    }}
                    type="text"
                    placeholder="Enter your name"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Special Instructions (Optional)</Form.Label>
                  <Form.Control
                    value={specialInstructions}
                    onChange={(e) => setSpecialInstructions(e.target.value)}
                    as={"textarea"}
                    rows={3}
                    type="text"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="required">Phone Number</Form.Label>
                  <Form.Control
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    type="number"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="required">Payment Method</Form.Label>
                  <Form.Select
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option value="" disabled>
                      Select Payment Method
                    </option>
                    <option value="Card">Card</option>
                    <option value="Cash">Cash</option>
                  </Form.Select>
                </Form.Group>

                <div className="d-flex align-items-center justify-content-between">
                  <span>Sub Total</span>
                  <span>PKR {orderDetail.total_Price}</span>
                </div>
              </Form>

              <div className="d-flex justify-content-center">
                <button onClick={confirmOrder} className="global-btn my-2">
                  confirm
                </button>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderPage;
