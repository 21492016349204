export const BASE_URL = "https://portal.diner-tab.com/api/";
// export const BASE_URL = "https://demo1.diner-tab.com/api/";

export const API_URL = {
  // GET_POS_DATA: "Getorderfromother/getposdata/",
  // GET_POS_DATA: "GetOrderFromOther/GetPOSData?tenantId=1",
  GET_POS_DATA: "GetOrderFromOther/GetPOSData",


  SAVING_RUNNING_ORDER: "Getorderfromother/SaveRunningOrder/",
};

// https://demo1.diner-tab.com/api/GetOrderFromOther/GetPOSData?tenantId=1
