import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaQrcode } from "react-icons/fa";

const TestPage = () => {
  let navigate = useNavigate();

  // useEffect(() => {
  //   setTimeout(() => {
  //     handleClick();
  //   }, 5000);
  // }, []);
  function handleClick() {
    // navigate("/menustart?restaurantId=1&TableName=T2");
    // navigate("/menustart?tenantId=1&TableName=T51");
    navigate("/menustart?tenantName=portal&tenantId=1&TableName=T4");
  }
  return (
    <div className="container text-center py-5">
      {/* <button onClick={handleClick}>Go to Menu Page</button> */}

      <FaQrcode size={30} color="#982121" />
      <h1 className="pt-3">Scan the QR Code to see the Menu</h1>
    </div>
  );
};

export default TestPage;
