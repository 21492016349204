import React, { useEffect, useState } from "react";
import data from "../Data.json";
import { useNavigate } from "react-router-dom";
import Cart from "./Cart";
import { MenuState } from "../context/MenuContext";
import { toast } from "react-toastify";

const ItemCards = (props) => {
  const { setCartData } = MenuState();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  let posData = props?.posData;
  // console.log(posData, "itemData");

  const [restaurantName, setRestaurantName] = useState("");

  useEffect(() => {
    // Create a URLSearchParams object to parse the query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Extract the TableName parameter
    const restaurantName = queryParams.get("tenantName");

    // Set the tableName state
    setRestaurantName(restaurantName);
  }, []);

  const handleClose = () => setShow(false);

  const itemData = posData?.foodItems
    .filter((item) => item.groupId === props?.sectionId)
    .map((x) => x);

  // console.log(itemData, "kkkk");

  const goToItemDetail = (detail) => {
    navigate("/menu-detail", { state: { detail, posData } });
  };

  const addToCart = (newData) => {
    setCartData((prevCartData) => {
      const existingIndex = prevCartData.cartItems.findIndex(
        (item) => item.Id === newData.id
      );
      let newCartItems;

      if (existingIndex !== -1) {
        // Item exists, update quantity and total price
        newCartItems = [...prevCartData.cartItems];
        const existingItem = newCartItems[existingIndex];
        const newQuantity = existingItem.Quantity + 1;
        const newTotalPrice = existingItem.ItemPrice * newQuantity;
        newCartItems[existingIndex] = {
          ...existingItem,
          Quantity: newQuantity,
          TotalPrice: newTotalPrice,
        };

        toast(`Cart Updated: Item Quantity Increased.`, {
          position: "top-center",
          type: "success",
          autoClose: 1400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // Item doesn't exist, add new item
        const newItem = {
          ...newData,
          Id: newData.id,
          Name: newData.itemName,
          ItemPrice: newData.price,
          Quantity: 1,
          TotalPrice: newData.price,
          Image: newData.image,
          Modifiers: [],
        };
        newCartItems = [...prevCartData.cartItems, newItem];

        toast(`Item added to cart.`, {
          position: "top-center",
          type: "success",
          autoClose: 1400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      // Recalculate the total price
      const newTotalPrice = newCartItems.reduce(
        (total, item) => total + item.TotalPrice,
        0
      );

      return {
        cartItems: newCartItems,
        total_Price: newTotalPrice,
      };
    });
  };
  return (
    <div>
      <Cart show={show} onHide={handleClose} />
      <div className="text-center pt-5"></div>
      {itemData?.map((x, index) => {
        return (
          <div key={index} id="section-items">
            <div className="d-flex flex-column bg-white shadow p-4 mt-4 position-relative">
              <div className="d-flex justify-content-between align-items-center ">
                <div className="food-card">
                  <h4
                    onClick={() => goToItemDetail(x)}
                    style={{ cursor: "pointer", width: "98%" }}
                  >
                    {x.itemName}
                  </h4>

                  <p className="m-0 pt-2">Price PKR {x.price}</p>
                </div>
                <div>
                  {x.imageFullPath === null ? (
                    <img
                      className="custom-card-img"
                      src="https://wiki.tripwireinteractive.com/TWIimages/4/47/Placeholder.png?20121020050736"
                      alt="kpg"
                    />
                  ) : (
                    <img
                      className="img-fluid"
                      // src={`https://portal.diner-tab/GoStoraImages/Company/1/Products/${x.imageFullPath}`}
                      src={`https://${restaurantName}.diner-tab.com/${x.imageFullPath}`}
                      alt="img"
                    />
                  )}
                </div>
              </div>
              <button onClick={() => addToCart(x)} className="global-btn mt-4">
                Add to Cart
              </button>

              <div className="rotate-btn text-white text-center fs-3">
                <span onClick={() => addToCart(x)}>+</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ItemCards;
