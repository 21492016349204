import React, { createContext, useContext } from "react";
import { useState } from "react";
const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [orderDetail, setOrderDetail] = useState([]);
  const [cartData, setCartData] = useState({
    cartItems: [],
    total_Price: 0,
  });
  const [orderResponse, setOrderResponse] = useState(null);

  const [resId, setResId] = useState();
  const [resName, setResName] = useState();
  const [tableName, setTableName] = useState();

  return (
    <MenuContext.Provider
      value={{
        orderDetail,
        setOrderDetail,
        cartData,
        setCartData,
        orderResponse,
        setOrderResponse,
        resId,
        setResId,
        resName,
        setResName,
        tableName,
        setTableName,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const MenuState = () => {
  return useContext(MenuContext);
};

export default MenuProvider;
