import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ItemCards from "./ItemCards";
import CustomHeader from "./CustomHeader";
import { MenuState } from "../context/MenuContext";
import Cart from "./Cart";

import { API_URL, BASE_URL } from "../global/Constant";
import apiFunctions from "../global/GlobalFunction";

const SectionSlider = (props) => {
  const tenantId = props?.restaurantId;
  // console.log(tenantId, "alwayss comes");
  const { cartData, resId } = MenuState();
  const [loading, setLoading] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [sectionId, setSectionID] = useState();
  const [show, setShow] = useState(false);
  const [posData, setPosData] = useState();

  const [tableName, setTableName] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  // const [tenantId, setTenantId] = useState("");

  useEffect(() => {
    // Create a URLSearchParams object to parse the query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Extract the TableName And Restaurant parameter
    const tableNameParam = queryParams.get("TableName");
    const restaurantName = queryParams.get("tenantName");
    // const restaurantId = queryParams.get("tenantId");

    // Set the tableName state
    setTableName(tableNameParam);
    setRestaurantName(restaurantName);
    // setTenantId(restaurantId);
    // console.log("TenantId:", tenantId); // Debug logging
  }, []);

  useEffect(() => {
    getPosData();
  }, []);

  async function getPosData() {
    try {
      setLoading(true);
      let getPosData = await apiFunctions.GET_REQUEST(
        // "https://thxdd077-7104.inc1.devtunnels.ms/api/GetOrderFromOther/GetPOSData?resturantId=1"
        // BASE_URL + API_URL.GET_POS_DATA + `?resturantId=${resId}`
        `${BASE_URL}${API_URL.GET_POS_DATA}?tenantId=${tenantId}`
      );
      let res = getPosData?.data;
      console.log(res, "kkkk");
      setLoading(false);

      setPosData(res);
    } catch (err) {
      console.log("An error occurred while fetching menus", err.message);
      setLoading(false);
    }
  }

  const foodGroups = posData?.foodGroups;
  const RestaurantDetail = posData?.resturantDetail;

  //console.log(RestaurantDetail, "Res Detail");

  const handleClose = () => setShow(false);

  const showSectionDetail = (id, index) => {
    setSectionID(id, index);
    setShowItems(true);
    console.log(sectionId, "section id");
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (foodGroups && foodGroups.length > 0) {
      const defaultIndex = 0;
      const defaultItem = foodGroups[defaultIndex];
      if (defaultItem) {
        showSectionDetail(defaultItem.id, defaultIndex);
      }
    }
  }, [foodGroups]);

  return (
    <>
      <div className="section-slider">
        <Cart show={show} onHide={handleClose} />
        <div className="order-main container-fluid py-3">
          <div className="row">
            <div className="first col-xl-5 col-lg-6 col-md-12 px-xl-5 px-lg-4">
              <CustomHeader
                onCartIconClick={() => setShow(true)}
                cartQty={cartData.cartItems.length}
              />
              <div className="text-center">
                <h4>Menu</h4>
                <p className="pt-4">Table # {tableName}</p>
              </div>

              {loading ? (
                <div
                  style={{
                    minHeight: "50vh",
                  }}
                  className="d-flex flex-column align-items-center justify-content-center"
                >
                  <svg
                    className="pl"
                    width="240"
                    height="240"
                    viewBox="0 0 240 240"
                  >
                    <circle
                      className="pl__ring pl__ring--a"
                      cx="120"
                      cy="120"
                      r="105"
                      fill="none"
                      stroke="#000"
                      stroke-width="20"
                      stroke-dasharray="0 660"
                      stroke-dashoffset="-330"
                      stroke-linecap="round"
                    ></circle>
                    <circle
                      className="pl__ring pl__ring--b"
                      cx="120"
                      cy="120"
                      r="35"
                      fill="none"
                      stroke="#000"
                      stroke-width="20"
                      stroke-dasharray="0 220"
                      stroke-dashoffset="-110"
                      stroke-linecap="round"
                    ></circle>
                    <circle
                      className="pl__ring pl__ring--c"
                      cx="85"
                      cy="120"
                      r="70"
                      fill="none"
                      stroke="#000"
                      stroke-width="20"
                      stroke-dasharray="0 440"
                      stroke-linecap="round"
                    ></circle>
                    <circle
                      className="pl__ring pl__ring--d"
                      cx="155"
                      cy="120"
                      r="70"
                      fill="none"
                      stroke="#000"
                      stroke-width="20"
                      stroke-dasharray="0 440"
                      stroke-linecap="round"
                    ></circle>
                  </svg>
                </div>
              ) : (
                <>
                  <Slider {...settings}>
                    {foodGroups?.map((x, index) => {
                      return (
                        <div
                          onClick={() => showSectionDetail(x.id, index)}
                          className="d-flex justify-content-center flex-column align-items-center pt-lg-4"
                          key={index}
                        >
                          {x.imageFullPath === null ? (
                            <img
                              className="custom-card-img"
                              src="https://wiki.tripwireinteractive.com/TWIimages/4/47/Placeholder.png?20121020050736"
                              alt="kpg"
                            />
                          ) : (
                            <img
                              className="img-fluid"
                              // src={`https://portal.diner-tab/GoStoraImages/Company/1/Products/${x.imageFullPath}`}
                              src={`https://${restaurantName}.diner-tab.com/${x.imageFullPath}`}
                              alt="img"
                            />
                          )}

                          <p className="pt-1 m-0">{x.groupName}</p>
                        </div>
                      );
                    })}
                  </Slider>

                  {showItems ? (
                    <ItemCards posData={posData} sectionId={sectionId} />
                  ) : null}
                </>
              )}
            </div>

            <div className="res-name col-xl-7">
              <div className="inner">
                <h3 className="text-center">
                  {restaurantName}
                  {/* {RestaurantDetail.resturantName} */}
                </h3>
                {/* <img
                  className="img-fluid"
                  // src={RestaurantDetail.Logo}
                  src="Resturant Name"
                  alt="img"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionSlider;
